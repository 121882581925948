<template>
  <div class="none_wrapper">
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
  export default {
  }
</script>