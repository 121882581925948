<template>
  <div>
    <AppBar />
    <v-main class="wrapper">
      <slot />
    </v-main>
    <AppFooter />
  </div>
</template>
<script>
export default {
  name: 'default-layout',
  components: {
    AppBar: () => import('@/components/AppBar.vue'),
    AppFooter: () => import('@/components/AppFooter.vue')
  }
}
</script>
<style scoped>

</style>